import config from '../../config';
export const getCustomFields = (tab, publicData) => {
    const customFields = config.custom.customFields[tab];
    let fields = {}
    customFields.map( (f) =>{
      const field = f.key;
      const value = publicData[field];
      fields[field] = value;
    })
    return fields;
}
  