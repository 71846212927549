import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ListingCard, HorizontalScroll  } from '../../components';

import css from './SectionPopularTutors.module.css';

const SectionPopularTutors = props => {
  const { rootClassName, className, popularTutors } = props;

  const classes = classNames(rootClassName || css.root, className);
  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <h2>Popular Tutors</h2>
      <div>
        {popularTutors ? (
          <HorizontalScroll className={css.promotedListingContainer}>
            {popularTutors.map(pl => (
              <ListingCard
                className={css.listingCard}
                key={pl.id.uuid}
                listing={pl}
                renderSizes={cardRenderSizes}
              />
            ))}
          </HorizontalScroll>
        ) : null}
      </div>
    </div>
  );
};

SectionPopularTutors.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionPopularTutors.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPopularTutors;
