import React, {useState, useEffect} from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import { getCustomFields } from '../../components/CustomFields/helpers';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const [assets, setAssets] = useState({});

  useEffect(() => {
    const currentListing = ensureOwnListing(listing);
    const { privateData } = currentListing.attributes;
    privateData && privateData.assets && setAssets(privateData.assets)
  }, []);

  const onDrop = (files, field) => {
    const url = 'https://api.cloudinary.com/v1_1/dtmtvrgdp/auto/upload';
    const formData = new FormData();
    
    let uploadedFiles = []
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('file', file);
      formData.append('upload_preset', 'askmeacademy');

      fetch(url, { method: 'POST', body: formData })
        .then((response) => response.text())
        .then((data) => {
          uploadedFiles = uploadedFiles.concat(JSON.parse(data))
          const updatedAssets = {...assets, [field]: [...assets[field] || [], ...uploadedFiles]}
          setAssets(updatedAssets);
        });
    }
  };
  const onRemoveFile = (index, field) => (e) => {
    e.stopPropagation();

    // const dt = new DataTransfer();
    // const file = assets[field][index];
    // const el = document.getElementById('attachment-dropzone');
    // for (let i = 0; i < el.files.length; i++) {
    //   const rowFile = el.files[i];
    //   let name = rowFile.name.split('.');
    //   name.splice(name.length - 1, 1);
    //   name = name.join('');
    //   if (name !== file.original_filename) dt.items.add(rowFile);
    // }
    // el.files = dt.files;
    const otherFiles = assets[field] && assets[field].filter((file, i) => i !== index)
    const updatedAssets = {...assets, [field]: otherFiles}
    setAssets(updatedAssets)
  };

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const customFields = getCustomFields('descriptionTab', publicData);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{ title, description, ...customFields }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, ...rest } = values;
          const updateValues = {
            title: title.trim(),
            description,
            publicData: { ...rest },
            privateData: { assets },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        onDrop={onDrop}
        onRemoveFile={onRemoveFile}
        assets={assets}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
