import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import config from '../../config';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className, setIsRSVPModalOpen } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <span className={css.highlightText}><FormattedMessage id="SectionHero.title1" /></span>
          <span className={css.nonHighlightedText}><FormattedMessage id="SectionHero.title2" /></span>
          <span className={css.nonHighlightedText}><FormattedMessage id="SectionHero.title3" /></span>
          
        </h1>
        {/* <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2> */}
        <div className={css.links}>
          {
            config.custom.preLaunch ? 
            <a href="#" className={css.secondaryButton} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsRSVPModalOpen(true);}}>
              RSVP NOW
            </a> :
            <>
              <NamedLink
                name="SearchPage"
                className={css.heroButton}
                >
                <FormattedMessage id="SectionHero.browseButton" />
              </NamedLink>
            </>
          }
          </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
