import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';
import { required } from '../../util/validators';

import css from './EditListingSocialMediaForm.module.css';

export const EditListingSocialMediaFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const facebookLabelMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.facebookLabel',
      });
      const facebookPlaceholderMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.facebookPlaceholder',
      });

      const facebookRequiredMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.facebookRequired',
      });

      const instagramLabelMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.instagramLabel',
      });
      const instagramPlaceholderMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.instagramPlaceholder',
      });

      const instagramRequiredMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.instagramRequired',
      });

      const twitterLabelMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.twitterLabel',
      });
      const twitterPlaceholderMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.twitterPlaceholder',
      });

      const twitterRequiredMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.twitterRequired',
      });

      const linkedinLabelMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.linkedinLabel',
      });
      const linkedinPlaceholderMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.linkedinPlaceholder',
      });

      const linkedinRequiredMessage = intl.formatMessage({
        id: 'EditListingSocialMediaForm.linkedinRequired',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSocialMediaForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSocialMediaForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="facebook"
            name="facebook"
            className={css.facebook}
            type="text"
            label={facebookLabelMessage}
            placeholder={facebookPlaceholderMessage}
            validate={required(facebookRequiredMessage)}
          />

          <FieldTextInput
            id="instagram"
            name="instagram"
            className={css.instagram}
            type="text"
            label={instagramLabelMessage}
            placeholder={instagramPlaceholderMessage}
            validate={required(instagramRequiredMessage)}
          />

          <FieldTextInput
            id="twitter"
            name="twitter"
            className={css.twitter}
            type="text"
            label={twitterLabelMessage}
            placeholder={twitterPlaceholderMessage}
            validate={required(twitterRequiredMessage)}
          />

          <FieldTextInput
            id="linkedin"
            name="linkedin"
            className={css.linkedin}
            type="text"
            label={linkedinLabelMessage}
            placeholder={linkedinPlaceholderMessage}
            validate={required(linkedinRequiredMessage)}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingSocialMediaFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingSocialMediaFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingSocialMediaFormComponent);
