/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const customFields = {
  descriptionTab: [
    {
      key: 'gender',
      label: 'Your pronouns',
      listingViewLabel: 'Gender',
      type: 'dropdown',
      visible: true,
      required_message: 'This field is required',
      options: [
        { key: '', label: 'Select gender identity' },
        { key: 'male', label: 'He/Him/His' },
        { key: 'female', label: 'She/Her/Hers' },
        { key: 'other', label: 'They/Them/Theirs' },
      ],
    },
    {
      key: 'have-you-ever-been-a-teacher',
      label: 'Have you ever been a teacher?',
      listingViewLabel: 'Teaching experience',
      type: 'radio',
      visible: true,
      isBoolean: true,
      required_message: 'This field is required',
      options: [
        { key: 'yes', label: 'Yes' },
        { key: 'no', label: 'No' },
      ],
    },
    {
      key: 'what-is-the-name-of-the-school-where-you-teach-or-most-recently-taught',
      label: 'What is the name of the school where you teach or most recently taught?',
      listingViewLabel: 'School',
      type: 'text',
      visible: false,
      placeholder: "Thomas Jefferson High School",
      required_message: 'Name of the school where you teach or most recently taught is required',
    },
    {
      key: 'as-a-teacher-did-you-obtain-certification',
      label: 'As a teacher, did you obtain certification?',
      listingViewLabel: 'Certifications',
      type: 'radio',
      visible: true,
      isBoolean: true,
      required_message: 'This field is required',
      options: [
        { key: 'yes', label: 'Yes' },
        { key: 'no', label: 'No' },
      ],
      hasAttachmentField: true,
      attachmentConfig: {
        key: "certification-attachments",
        label: "Upload your certificates",
        tip: "Tip: You can attach pdfs,docs and images",
        heading: "Uploaded certificates"
      }
    },
    {
      key: 'how-many-years-of-tutoring-experience-do-you-have',
      label: 'How many years of tutoring experience do you have?',
      listingViewLabel: 'Tutoring experience (years)',
      type: 'radio',
      visible: true,
      required_message: 'This field is required',
      options: [
        { key: '0', label: '0' },
        { key: '1', label: '1' },
        { key: '2-4', label: '2-4' },
        { key: '5-10', label: '5-10' },
        { key: '10+', label: '10+' },
      ],
      hasAttachmentField: true,
      attachmentConfig: {
        key: "experience-attachments",
        label: "Upload documents to prove your experience",
        tip: "Tip: You can attach pdfs,docs and images",
        heading: "Uploaded documents"
      }
    },
    {
      key: 'which-type-of-students-do-you-like-to-tutor',
      label: 'Which type of students do you like to tutor?',
      listingViewLabel: 'Student\'s level',
      type: 'checkboxGroup',
      visible: true,
      required_message: 'This filed is required',
      options: [
        { key: 'elementary', label: 'Elementary' },
        { key: 'middle-school', label: 'Middle School' },
        { key: 'high-school', label: 'High School' },
        { key: 'college', label: 'College' },
        { key: 'adult', label: 'Adult' },
      ],
    },
    // {
    //   key: 'where-do-you-prefer-having-lessons',
    //   label: 'Where do you prefer having lessons?',
    //   listingViewLabel: 'Lesson preferences',
    //   type: 'checkboxGroup',
    //   visible: true,
    //   required_message: 'This filed is required',
    //   options: [
    //     { key: 'students-location', label: 'Student\'s location' },
    //     { key: 'library', label: 'Library' },
    //     { key: 'location-i-select', label: 'Location I select' },
    //     { key: 'online', label: 'Online' },
    //   ],
    // },
    {
      key: 'which-of-these-do-you-find-rewarding',
      label: 'Which of these do you find rewarding?',
      type: 'checkboxGroup',
      visible: false,
      required_message: 'This filed is required',
      options: [
        { key: 'improving-your-students-grades', label: 'Improving your student\'s grades' },
        { key: 'helping-students-get-ahead', label: 'Helping students get ahead' },
        { key: 'giving-a-student-confidence-to-succeed', label: 'Giving a student confidence to succeed' },
        { key: 'providing-test-prep', label: 'Providing test prep' },
        { key: 'teaching-a-hobby', label: 'Teaching a hobby' },
      ],
    },
    // {
    //   key: 'do-you-have-access-to-a-car',
    //   label: 'Do you have access to a car?',
    //   listingViewLabel: 'Have a car?',
    //   type: 'radio',
    //   visible: true,
    //   isBoolean: true,
    //   required_message: 'This field is required',
    //   options: [
    //     { key: 'yes', label: 'Yes' },
    //     { key: 'no', label: 'No' },
    //   ],
    // },
    // {
    //   key: 'are-you-interested-in-online-tutoring',
    //   label: 'Are you interested in online tutoring?',
    //   type: 'radio',
    //   visible: false,
    //   required_message: 'This field is required',
    //   options: [
    //     { key: 'yes', label: 'Yes' },
    //     { key: 'no', label: 'No' },
    //   ],
    // },
    {
      key: 'how-many-hours-per-week-do-you-currently-tutor-outside-of-AskMeAcademy',
      label: 'How many hours per week do you currently tutor outside of AskMeAcademy?',
      type: 'radio',
      visible: false,
      required_message: 'This field is required',
      options: [
        { key: '0', label: '0' },
        { key: '1-5', label: '1-5' },
        { key: '6-10', label: '6-10' },
        { key: '11-20', label: '11-20' },
        { key: '21-35', label: '21-35' },
        { key: '35+', label: '35+' },
      ],
    },
  ],
  subjectsTab: [
    {
      key: 'stem',
      label: 'STEM',
      type: 'checkboxGroup',
      required_message: null,
      options: [
        { key: 'math', label: 'Math' },
        { key: 'statistics', label: 'Statistics' },
        { key: 'chemistry', label: 'Chemistry' },
        { key: 'physics', label: 'Physics' },
        { key: 'computer-science', label: 'Computer Science' },
        { key: 'engineering', label: 'Engineering' },
        { key: 'biology', label: 'Biology' },
        { key: 'geology', label: 'Geology' },
        { key: 'astronomy', label: 'Astronomy' },
      ],
    },
    {
      key: 'social',
      label: 'Social Sciences',
      type: 'checkboxGroup',
      required_message: null,
      options: [
        { key: 'economics', label: 'Economics' },
        { key: 'history', label: 'History' },
        { key: 'political-science', label: 'Political Science' },
        { key: 'sociology', label: 'Sociology' },
        { key: 'humanities', label: 'Humanities' },
        { key: 'anthropology', label: 'Anthropology' },
      ],
    },
    {
      key: 'arts',
      label: 'Language Arts',
      type: 'checkboxGroup',
      required_message: null,
      options: [
        { key: 'spanish', label: 'Spanish' },
        { key: 'french', label: 'French' },
        { key: 'english', label: 'English' },
        { key: 'chinese', label: 'Chinese' },
        { key: 'arabic', label: 'Arabic' },
      ],
    },
  ],
  studentProfileFields: [
    {
      key: 'what-is-the-name-of-the-school-where-you-study',
      label: 'What is the name of the school where you study?',
      type: 'text',
      visible: true,
      placeholder: "Thomas Jefferson High School",
      required_message: null,
    },
    {
      key: 'are-you-a-minor',
      label: 'Are you a minor?',
      type: 'radio',
      visible: true,
      required_message: 'This field is required',
      options: [
        { key: 'yes', label: 'Yes' },
        { key: 'no', label: 'No' },
      ],
    },
    {
      key: 'what-is-your-age',
      label: 'What is your age?',
      type: 'number',
      visible: true,
      placeholder: "15",
      required_message: null,
    },
    {
      key: 'which-level-you-are-in',
      label: 'Which level you are in?',
      type: 'radio',
      visible: true,
      required_message: 'This filed is required',
      options: [
        { key: 'elementary', label: 'Elementary' },
        { key: 'middle-school', label: 'Middle School' },
        { key: 'high-school', label: 'High School' },
        { key: 'college', label: 'College' },
        { key: 'adult', label: 'Adult' },
      ],
    },
  ]
}

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'stem',
    label: 'STEM',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_stem'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        ...customFields.subjectsTab[0].options,
      ],
    },
  },
  {
    id: 'social',
    label: 'Social Sciences',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_social'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        ...customFields.subjectsTab[1].options,
      ],
    },
  },
  {
    id: 'arts',
    label: 'Language Arts',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_arts'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        ...customFields.subjectsTab[2].options,
      ],
    },
  },
  {
    id: 'gender',
    label: 'Gender',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_gender'],
    config: {
      options: [
        ...customFields.descriptionTab[0].options.slice(1, 3+1),
      ],
    },
  },
  {
    id: 'how-many-years-of-tutoring-experience-do-you-have',
    label: 'Years of experience',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_how-many-years-of-tutoring-experience-do-you-have'],
    config: {
      options: [
        ...customFields.descriptionTab[4].options,
      ],
    },
  },
  {
    id: 'teacherSessionsCount',
    label: 'Completed Sessions',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_teacherSessionsCount'],
    config: {
      options: [
        { key: '0,6', label: '0-5' },
        { key: '5,11', label: '5-10' },
        { key: '10,16', label: '10-15' },
        { key: '15,21', label: '15-20' },
        { key: '20,', label: '20+' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const preLaunch = false