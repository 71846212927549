import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './SectionOnlineLessons.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import {
  IconRight,
  IconArrowHead,
  NamedLink,
} from '../../components';
import config from '../../config';
import arrowRight from '../../assets/right-arrow.png';
import { Fade } from 'react-slideshow-image';
import onlineLessonImage1 from './images/onlineLessions1.png';
import onlineLessions2 from './images/onlineLessions2.png';
import onlineLessions3 from './images/onlineLessions3.png';
import laptopFrame from './images/laptopFrame.png';

const SectionOnlineLessons = props => {
  const { intl } = props;

  const properties = {
    infinite: true,
    prevArrow: <div></div>,
    nextArrow: <div></div>
  };

  return (
    <div className={css.whyUsContainer}>
      <div className={css.whyUs}>
        <h1 className={css.sectionMainHeading}><FormattedMessage id="SectionOnlineLessons.sectionTite" /></h1>
        <p className={css.sectionMainParagraph}><FormattedMessage id="SectionOnlineLessons.mainParagraph" /></p>
        {/* <img className={css.laptopFrame} src={laptopFrame} alt="Laptop Frame" /> */}
        <img className={css.mobileImage} src={onlineLessonImage1} alt="Mobile Image" />
        <div className={css.slideContainer}>
          <Fade  {...properties}>
            <div className={css.eachFade}>
              <div>
                <img src={onlineLessonImage1} className={css.image} />
              </div>
            </div>
            <div className={css.eachFade}>
              <div>
                <img src={onlineLessions2} className={css.image} />
              </div>
            </div>
            <div className={css.eachFade}>
              <div>
                <img src={onlineLessions3} className={css.image} />
              </div>
            </div>
          </Fade>
        </div>
        <div className={css.right}>
          <div className={css.pointsWrapper}>
            <div className={css.points}>
              <span><img src={arrowRight} /></span>
              <h3>
                <span className={css.highlight}><FormattedMessage id="SectionOnlineLessons.meet" /></span>
                <FormattedMessage id="SectionOnlineLessons.withTheExpert" />
              </h3>
            </div>

            <div className={css.points}>
              <span><img src={arrowRight} /></span>
              <h3>
                <span className={css.highlight}><FormattedMessage id="SectionOnlineLessons.save" /></span>
                <FormattedMessage id="SectionOnlineLessons.timeAndEasily" />
              </h3>
            </div>

            <div className={css.points}>
              <span><img src={arrowRight} /></span>
              <h3>
                <span className={css.highlight}><FormattedMessage id="SectionOnlineLessons.collaborate" /></span>
                <FormattedMessage id="SectionOnlineLessons.withOnlineFeatures" />
              </h3>
            </div>
            <NamedLink
              name="SearchPage"
              className={css.heroButton}
            >
              <FormattedMessage id="SectionOnlineLessons.howItWorksButton" />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionOnlineLessons.defaultProps = {};

SectionOnlineLessons.propTypes = {};

export default SectionOnlineLessons;
