import React from 'react';
import { FieldSelect, FieldTextInput, FieldCheckbox, FieldCheckboxGroup, FieldRadioButton, IconClose } from '../../components';
import { required, composeValidators } from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';
import css from './CustomFields.module.css';
import Dropzone from 'react-dropzone';

const CustomFields = props => {
  const { fields, onDrop, onRemoveFile, assets, setIsAddSubjectModalOpen, onAddSubject, customSubjects } = props;
  return fields
    ? fields.map(fieldConfig => {
        switch (fieldConfig.type) {
          case 'dropdown':
            return (
              <FieldSelect
                key={fieldConfig.key}
                className={css.customField}
                type="select"
                name={fieldConfig.key}
                id={fieldConfig.key}
                label={fieldConfig.label}
                validate={fieldConfig.required_message ? composeValidators(required(fieldConfig.required_message)) : null}
                multiple={fieldConfig.multiselect === 'true'}
              >
                {fieldConfig.options.map(o => (
                  <option key={o.key} value={o.key}>
                    {o.label}
                  </option>
                ))}
              </FieldSelect>
            );
          case 'checkboxGroup':
            return (
              <div>
                <FieldCheckboxGroup
                  className={css.customCheckboxGroup}
                  id={fieldConfig.key}
                  label={fieldConfig.label}
                  name={fieldConfig.key}
                  options={!!customSubjects && customSubjects[fieldConfig.key] ? [ ...fieldConfig.options, ...customSubjects[fieldConfig.key] ]: fieldConfig.options}
                  validate={fieldConfig.required_message ? composeValidators(required(fieldConfig.required_message)) : null}
                />
                {!!onAddSubject ?
                  <p className={css.addSubject} onClick={() => {setIsAddSubjectModalOpen(true); onAddSubject(fieldConfig.key);}}><FormattedMessage id="CustomFields.addMoreSubject" /></p>
                : null}
              </div>
            );
          case 'checkbox':
            return (
              <FieldCheckbox
                key={fieldConfig.key}
                id={fieldConfig.key}
                className={css.customField}
                name={fieldConfig.key}
                label={fieldConfig.label}
                value="true"
                validate={fieldConfig.required_message ? composeValidators(required(fieldConfig.required_message)) : null}
              />
            );
          case 'radio':
            return (
              <div className={css.customField}>
                <label htmlFor={fieldConfig.key}>{fieldConfig.label}</label>
                {
                  fieldConfig.options.map(o => (
                  <FieldRadioButton
                    key={`${fieldConfig.key}-option-${o.key}`}
                    id={`${fieldConfig.key}-option-${o.key}`}
                    name={fieldConfig.key}
                    label={o.label}
                    value={o.key}
                    showAsRequired={fieldConfig.required_message}
                    validate={fieldConfig.required_message ? composeValidators(required(fieldConfig.required_message)) : null}
                  />))
                }
                {
                  fieldConfig.hasAttachmentField ? 
                    <div className={css.dropzoneWrapper}>
                      <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, fieldConfig.attachmentConfig?.key)} accept='image/*,application/pdf,.doc,.docx'>
                        {({ getRootProps, getInputProps }) => (
                          <section className='container'>
                            <div {...getRootProps({ className: css.dropzoneContainer })}>
                              <input {...getInputProps()} id='attachment-dropzone' />
                              <p>{fieldConfig.attachmentConfig?.tip}</p>
                              <h6>{fieldConfig.attachmentConfig?.label}</h6>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                   : null
                }
                {assets && assets[fieldConfig.attachmentConfig?.key]?.length > 0 ? (
                  <div className={css.assetsContainer}>
                    <h2>
                      {fieldConfig.attachmentConfig?.heading}
                    </h2>
                    <div className={css.assets}>
                      {assets[fieldConfig.attachmentConfig.key].map((ss, index) => {
                        return (
                          <div style={{ position: 'relative' }}>
                            <div className={css.asset}>
                              {ss.resource_type === 'image' && (
                                <img src={ss.secure_url} alt={ss.public_id} />
                              )}
                              {ss.resource_type === 'raw' && <p>{ss.public_id}</p>}
                            </div>
                            <div className={css.removeItem} onClick={onRemoveFile(index, fieldConfig.attachmentConfig.key)}>
                              <IconClose size='normal' className={css.removeIcon} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          default:
            return (
              <FieldTextInput
                key={fieldConfig.key}
                id={fieldConfig.key}
                name={fieldConfig.key}
                className={css.customField}
                type={fieldConfig.type}
                label={fieldConfig.label}
                placeholder={fieldConfig.placeholder}
                validate={fieldConfig.required_message ? composeValidators(required(fieldConfig.required_message)) : null}
              />
            );
        }
      })
    : null;
};
export default CustomFields;
