import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, Button, FieldTextInput } from '../../components';

import css from './EditListingAddSubjectForm.module.css';

export const EditListingAddSubjectFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        handleSubmit,
        intl,
      } = formRenderProps;

      const AddSubjectMessage = intl.formatMessage({
        id: 'EditListingAddSubjectForm.label',
      });

      const AddSubjectPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAddSubjectForm.placeholder',
      });

      const classes = classNames(css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>

          <FieldTextInput
            className={css.customSubject}
            type="text"
            name="customSubject"
            id="customSubject"
            label={AddSubjectMessage}
            placeholder={AddSubjectPlaceholderMessage}
          />

          <Button
            className={css.submitButton}
            type="submit"
          >
            <FormattedMessage id="EditListingAddSubjectForm.button" />
          </Button>
        </Form>
      );
    }}
  />
);


EditListingAddSubjectFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

export default compose(injectIntl)(EditListingAddSubjectFormComponent);
