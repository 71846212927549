import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities, entityRefs } from '../../util/data';
import { storableError } from '../../util/errors';
import config from '../../config';
import { sendUserInfo } from '../../util/api';


// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const QUERY_PROMOTED_LISTINGS_REQUEST = 'app/LandingPage/QUERY_PROMOTED_LISTINGS_REQUEST';
export const QUERY_PROMOTED_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_PROMOTED_LISTINGS_SUCCESS';
export const QUERY_PROMOTED_LISTINGS_ERROR = 'app/LandingPage/QUERY_PROMOTED_LISTINGS_ERROR';

export const SUBMIT_USER_INFO_REQUEST = 'app/LandingPage/SUBMIT_USER_INFO_REQUEST';
export const SUBMIT_USER_INFO_SUCCESS = 'app/LandingPage/SUBMIT_USER_INFO_SUCCESS';
export const SUBMIT_USER_INFO_ERROR = 'app/LandingPage/SUBMIT_USER_INFO_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryPromotedListingsError: null,
  promotedListingRefs: [],
  submitUserInfoSuccess: false,
  submitUserInfoError: null,
  submitUserInfoInProgress: false,
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_PROMOTED_LISTINGS_REQUEST:
      return {
        ...state,
        promotedListingRefs: state.promotedListingRefs,
        queryPromotedListingsError: null,
      };
    case QUERY_PROMOTED_LISTINGS_SUCCESS:
      return { ...state, promotedListingRefs: payload.listingRefs };
    case QUERY_PROMOTED_LISTINGS_ERROR:
      return { ...state, promotedListingRefs: [], queryPromotedListingsError: payload };

    case SUBMIT_USER_INFO_REQUEST:
      return { ...state, submitUserInfoInProgress: true, submitUserInfoSuccess: false, submitUserInfoError: null };
    case SUBMIT_USER_INFO_SUCCESS:
      return { ...state, submitUserInfoInProgress: false, submitUserInfoSuccess: true, submitUserInfoError: null  };
    case SUBMIT_USER_INFO_ERROR:
      return { ...state, submitUserInfoInProgress: false, submitUserInfoSuccess: false, submitUserInfoError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryPromotedListingsRequest = () => ({
  type: QUERY_PROMOTED_LISTINGS_REQUEST,
});

export const queryPromotedListingsSuccess = listingRefs => ({
  type: QUERY_PROMOTED_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryPromotedListingsError = e => ({
  type: QUERY_PROMOTED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const submitUserInfoRequest = () => ({
  type: SUBMIT_USER_INFO_REQUEST,
});

export const submitUserInfoSuccess = listingRefs => ({
  type: SUBMIT_USER_INFO_SUCCESS,
  payload: { listingRefs },
});

export const submitUserInfoError = e => ({
  type: SUBMIT_USER_INFO_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryPromotedListings = () => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  return sdk.listings
    .query({
      meta_popular: true,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    })
    .then(response => {
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryPromotedListingsSuccess(listingRefs));

      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
};

export const submitUserInfo = (values) => (dispatch, getState, sdk) => {
  const handleSucces = response => {
    dispatch(submitUserInfoSuccess());
  };

  const handleError = e => {
    dispatch(submitUserInfoError(e))
    throw e;
  };

  dispatch(submitUserInfoRequest());

  return sendUserInfo(values)
    .then(handleSucces)
    .catch(handleError);
};


export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(setInitialState());

  return Promise.all([dispatch(queryPromotedListings())]);
};
