import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './SectionWhyUs.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';
import {
  IconRight,
  IconArrowHead,
} from '../../components';
import config from '../../config';
import arrowRight from '../../assets/right-arrow.png';

const SectionWhyUs = props => {
  const { intl } = props;
  return (
    <div className={css.whyUsContainer}>
      <div className={css.whyUs}>
        <div className={css.left}></div>
        <div className={css.right}>
          <div className={css.title}>
            <FormattedMessage id="SectionWhyUs.title" />
          </div>
          <div className={css.pointsWrapper}>
            <div className={css.points}>
              <span><img src={arrowRight} /></span>
              <h3>
                <span className={css.highlight}><FormattedMessage id="SectionWhyUs.find" /></span>
                <FormattedMessage id="SectionWhyUs.becomeTutorOrBookSession" />
              </h3>
            </div>

            <div className={css.points}>
              <span><img src={arrowRight} /></span>
              <h3>
              <span className={css.highlight}><FormattedMessage id="SectionWhyUs.pick" /></span>
                <FormattedMessage id="SectionWhyUs.pickTheTutoringCategory" />
              </h3>
            </div>

            <div className={css.points}>
              <span><img src={arrowRight} /></span>
              <h3>
              <span className={css.highlight}><FormattedMessage id="SectionWhyUs.meet" /></span>
                <FormattedMessage id="SectionWhyUs.meetTutor" />
              </h3>
            </div>

            <div className={css.points}>
              <span><img src={arrowRight} /></span>
              <h3>
              <span className={css.highlight}><FormattedMessage id="SectionWhyUs.start" /></span>
                <FormattedMessage id="SectionWhyUs.virtualChat" />
              </h3>
            </div>
          </div>
          {
            config.custom.preLaunch ? null :
            <div className={css.findCustomers}>
              <h1 className={css.findCustomersTitle}>
                <FormattedMessage id="SectionSearch.becomeTutorOrBookSessionHeading" />
              </h1>
              <div className={css.links}>
                <NamedLink name="NewListingPage" className={css.heroButton}>
                  Become a Tutor
                </NamedLink>
                <NamedLink name="SearchPage" className={css.secondaryButton}>
                  Browse Tutors
                </NamedLink>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

SectionWhyUs.defaultProps = {};

SectionWhyUs.propTypes = {};

export default SectionWhyUs;
