import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingSubjectsForm, EditListingAddSubjectForm } from '../../forms';
import { ListingLink, Modal, } from '../../components';
import { getCustomFields } from '../../components/CustomFields/helpers';
import config from '../../config';
import { uniqBy } from 'lodash';

import css from './EditListingSubjectsPanel.module.css';

const EditListingSubjectsPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const [isAddSubjectModalOpen, setIsAddSubjectModalOpen] = useState(false);
  const [subjectKey, setSubjectKey] = useState(null);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const customFieldsConfig = config.custom.customFields['subjectsTab'];
  const stemOptions = customFieldsConfig.find(k => k.key === 'stem').options.map(option => option.key);
  const socialOptions = customFieldsConfig.find(k => k.key === 'social').options.map(option => option.key);
  const artsOptions = customFieldsConfig.find(k => k.key === 'arts').options.map(option => option.key);

  const customStemSubjects = publicData.stem ?  [...publicData?.stem?.filter((k => !stemOptions.includes(k)))].map(k => {
    return {key: k, label: k}
  }) : null;
  const customSocialSubjects = publicData.social ?[...publicData?.social?.filter((k => !socialOptions.includes(k)))].map(k => {
    return {key: k, label: k}
  }) : null;
  const customArtsSubjects = publicData.arts ? [...publicData?.arts?.filter((k => !artsOptions.includes(k)))].map(k => {
    return {key: k, label: k}
  }) : null;
  const [customSubjects, setCustomSubjects] = useState({stem: customStemSubjects, social: customSocialSubjects, arts: customArtsSubjects});
  
  const customFields = getCustomFields('subjectsTab', publicData);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const handleSubjectAdd = values => {
    const { customSubject } = values;
    setIsAddSubjectModalOpen(false);
    if(!!customSubject){
      const updatedSubjects = {
        ...customSubjects,
        [subjectKey]: uniqBy( customSubjects[subjectKey] ? [...customSubjects[subjectKey] , {key: customSubject, label: customSubject}] : [{key: customSubject, label: customSubject}], function (e) {
          return e.key;
        })
      }
      setCustomSubjects(updatedSubjects)
    }
  }
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingSubjectsPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingSubjectsPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingSubjectsPanel.createListingTitle" />
  );

  const initialValues = { ...customFields };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingSubjectsForm
        className={css.form}
        initialValues={initialValues}
        onSubmit={values => {
          const { ...rest } = values;
          const updatedValues = {
            publicData: { ...rest },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        publicData={publicData}
        setIsAddSubjectModalOpen={setIsAddSubjectModalOpen}
        onAddSubject={setSubjectKey}
        customSubjects={customSubjects}
      />
      <Modal
        id="AddSubjectModal"
        isOpen={isAddSubjectModalOpen}
        onClose={() => setIsAddSubjectModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EditListingAddSubjectForm
          formId="EditListingAddSubjectForm"
          onSubmit={handleSubjectAdd}
        />
      </Modal>
    </div>
  );
};

EditListingSubjectsPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingSubjectsPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingSubjectsPanel;
