import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg
        className={className}
        {...rest}
        width="141"
        height="26"
        viewBox="0 0 370.6 96.9"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FF6600" points="29.5,96.5 29.5,63.3 15,63.3 15,96.5 0.5,96.5 0.5,63.3 2.7,59.4 11.1,44.9 11.7,43.8 36.8,43.8 
          61.8,43.8 62.5,44.9 70.8,59.4 73.1,63.3 73.1,96.5 58.6,96.5 58.6,63.3 44,63.3 44,96.5 36.8,96.5 29.5,96.5 "/>
        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#203949" points="53.5,29.3 36.8,0.4 20.1,29.3 36.8,29.3 53.5,29.3 "/>
        <path fill="#203949" d="M131,57.4l-14.1-33.1h-6.7L96.1,57.4h7.4l3-7.4h13.9l3,7.4H131L131,57.4z M117.8,43.6h-8.7l4.4-10.7L117.8,43.6
          L117.8,43.6z M160.5,47.7L160.5,47.7c0-1.5-0.2-2.8-0.7-3.8c-0.5-1-1.1-1.9-2-2.7c-0.9-0.8-2-1.4-3.3-2c-1.3-0.5-2.8-1-4.5-1.5
          c-1.4-0.4-2.6-0.7-3.5-1c-0.9-0.3-1.7-0.6-2.2-0.9c-0.5-0.3-0.9-0.7-1.1-1.1c-0.2-0.4-0.3-0.9-0.3-1.4v-0.1c0-0.8,0.4-1.5,1.1-2
          c0.7-0.5,1.8-0.8,3.2-0.8c1.4,0,2.8,0.3,4.2,0.9c1.4,0.6,2.8,1.4,4.3,2.4l3.8-5.4c-1.7-1.3-3.5-2.4-5.6-3.1c-2-0.7-4.2-1.1-6.6-1.1
          c-1.7,0-3.2,0.2-4.6,0.7c-1.4,0.5-2.6,1.1-3.6,2c-1,0.9-1.8,1.9-2.4,3.1c-0.6,1.2-0.8,2.6-0.8,4.1v0.1c0,1.6,0.3,3,0.8,4.1
          c0.5,1.1,1.3,2,2.2,2.7c0.9,0.7,2.1,1.4,3.4,1.9c1.4,0.5,2.9,1,4.5,1.4c1.4,0.3,2.5,0.7,3.4,1c0.9,0.3,1.5,0.6,2,0.9
          c0.5,0.3,0.8,0.6,1,1c0.2,0.4,0.3,0.8,0.3,1.2v0.1c0,1-0.4,1.7-1.2,2.3c-0.8,0.5-2,0.8-3.5,0.8c-1.9,0-3.6-0.4-5.2-1.1
          c-1.6-0.7-3.1-1.7-4.6-2.9l-4.3,5.1c2,1.8,4.2,3.1,6.6,4c2.4,0.9,4.9,1.3,7.4,1.3c1.8,0,3.4-0.2,4.9-0.7c1.5-0.5,2.7-1.1,3.8-2
          s1.9-1.9,2.5-3.2C160.2,50.7,160.5,49.3,160.5,47.7L160.5,47.7z M197.5,57.4l-14-18.9l13.4-13.9h-8.7l-13.3,14.4V24.5h-7.2v32.9h7.2
          v-10l3.8-3.9l10.2,14H197.5L197.5,57.4z M249.5,57.4V24.5h-7.8l-8.6,13.9l-8.6-13.9h-7.8v32.9h7.1V36.1l9.2,13.9h0.2l9.2-14v21.4
          H249.5L249.5,57.4z M283.1,57.4v-6.4h-17.8V44h15.5v-6.4h-15.5V31h17.6v-6.4h-24.7v32.9H283.1L283.1,57.4z"/>
        <path fill="#FF6600" d="M122.2,96.5l-10.5-24.8h-5L96.1,96.5h5.5l2.2-5.5h10.4l2.2,5.5H122.2L122.2,96.5z M112.4,86.2h-6.5l3.3-8
          L112.4,86.2L112.4,86.2z M162.2,92.5l-3.4-3.5c-1,0.9-1.9,1.6-2.9,2.1c-1,0.5-2.2,0.8-3.6,0.8c-1.1,0-2-0.2-2.9-0.6
          c-0.9-0.4-1.6-1-2.2-1.7c-0.6-0.7-1.1-1.5-1.5-2.5c-0.4-0.9-0.5-1.9-0.5-3v-0.1c0-1.1,0.2-2,0.5-3c0.4-0.9,0.8-1.7,1.5-2.4
          c0.6-0.7,1.4-1.3,2.3-1.7c0.9-0.4,1.8-0.6,2.9-0.6c1.2,0,2.4,0.2,3.4,0.7c1,0.5,2,1.2,2.9,2l3.4-4c-0.6-0.6-1.2-1.1-1.8-1.5
          c-0.7-0.5-1.4-0.9-2.1-1.2c-0.8-0.3-1.6-0.6-2.6-0.8c-0.9-0.2-2-0.3-3.1-0.3c-1.9,0-3.6,0.3-5.2,1c-1.6,0.7-2.9,1.6-4,2.7
          c-1.1,1.2-2,2.5-2.6,4c-0.6,1.5-0.9,3.2-0.9,4.9v0.1c0,1.8,0.3,3.4,0.9,5c0.6,1.5,1.5,2.9,2.6,4c1.1,1.1,2.5,2,4,2.7
          c1.5,0.7,3.2,1,5,1c1.2,0,2.3-0.1,3.2-0.3c1-0.2,1.8-0.5,2.7-0.9c0.8-0.4,1.6-0.8,2.2-1.4C160.9,93.8,161.5,93.2,162.2,92.5
          L162.2,92.5z M205,96.5l-10.5-24.8h-5l-10.5,24.8h5.5l2.2-5.5h10.4l2.2,5.5H205L205,96.5z M195.1,86.2h-6.5l3.3-8L195.1,86.2
          L195.1,86.2z M246,84.2L246,84.2c0-1.8-0.3-3.4-0.9-4.9c-0.6-1.5-1.5-2.8-2.7-3.9c-1.2-1.1-2.5-2-4.1-2.6c-1.6-0.6-3.4-0.9-5.3-0.9
          h-9.6v24.6h9.6c1.9,0,3.7-0.3,5.3-0.9c1.6-0.6,3-1.5,4.1-2.6c1.2-1.1,2.1-2.4,2.7-3.9C245.7,87.5,246,85.9,246,84.2L246,84.2z
            M240.3,84.2c0,1.1-0.2,2.1-0.5,3c-0.4,0.9-0.9,1.7-1.5,2.3c-0.6,0.7-1.4,1.2-2.3,1.5c-0.9,0.4-1.9,0.5-3,0.5h-4.2V76.7h4.2
          c1.1,0,2.1,0.2,3,0.5c0.9,0.4,1.7,0.9,2.3,1.5c0.6,0.7,1.1,1.4,1.5,2.4C240.1,82.1,240.3,83.1,240.3,84.2L240.3,84.2L240.3,84.2z
            M284,96.5v-4.8h-13.4v-5.2h11.6v-4.8h-11.6v-5h13.2v-4.8h-18.5v24.6H284L284,96.5z M328,96.5V71.9h-5.8l-6.5,10.4l-6.5-10.4h-5.8
          v24.6h5.3v-16l6.9,10.4h0.1l6.9-10.5v16.1H328L328,96.5z M370.2,71.9h-6.2l-6,9.9l-5.9-9.9h-6.3l9.5,14.9v9.7h5.4v-9.8L370.2,71.9
          L370.2,71.9z"/>
      </svg>
   

    );
  }

  return (
    <svg
        className={className}
        {...rest}
        width="141"
        height="26"
        viewBox="0 0 370.6 96.9"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FF6600" points="29.5,96.5 29.5,63.3 15,63.3 15,96.5 0.5,96.5 0.5,63.3 2.7,59.4 11.1,44.9 11.7,43.8 36.8,43.8 
          61.8,43.8 62.5,44.9 70.8,59.4 73.1,63.3 73.1,96.5 58.6,96.5 58.6,63.3 44,63.3 44,96.5 36.8,96.5 29.5,96.5 "/>
        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#203949" points="53.5,29.3 36.8,0.4 20.1,29.3 36.8,29.3 53.5,29.3 "/>
        <path fill="#203949" d="M131,57.4l-14.1-33.1h-6.7L96.1,57.4h7.4l3-7.4h13.9l3,7.4H131L131,57.4z M117.8,43.6h-8.7l4.4-10.7L117.8,43.6
          L117.8,43.6z M160.5,47.7L160.5,47.7c0-1.5-0.2-2.8-0.7-3.8c-0.5-1-1.1-1.9-2-2.7c-0.9-0.8-2-1.4-3.3-2c-1.3-0.5-2.8-1-4.5-1.5
          c-1.4-0.4-2.6-0.7-3.5-1c-0.9-0.3-1.7-0.6-2.2-0.9c-0.5-0.3-0.9-0.7-1.1-1.1c-0.2-0.4-0.3-0.9-0.3-1.4v-0.1c0-0.8,0.4-1.5,1.1-2
          c0.7-0.5,1.8-0.8,3.2-0.8c1.4,0,2.8,0.3,4.2,0.9c1.4,0.6,2.8,1.4,4.3,2.4l3.8-5.4c-1.7-1.3-3.5-2.4-5.6-3.1c-2-0.7-4.2-1.1-6.6-1.1
          c-1.7,0-3.2,0.2-4.6,0.7c-1.4,0.5-2.6,1.1-3.6,2c-1,0.9-1.8,1.9-2.4,3.1c-0.6,1.2-0.8,2.6-0.8,4.1v0.1c0,1.6,0.3,3,0.8,4.1
          c0.5,1.1,1.3,2,2.2,2.7c0.9,0.7,2.1,1.4,3.4,1.9c1.4,0.5,2.9,1,4.5,1.4c1.4,0.3,2.5,0.7,3.4,1c0.9,0.3,1.5,0.6,2,0.9
          c0.5,0.3,0.8,0.6,1,1c0.2,0.4,0.3,0.8,0.3,1.2v0.1c0,1-0.4,1.7-1.2,2.3c-0.8,0.5-2,0.8-3.5,0.8c-1.9,0-3.6-0.4-5.2-1.1
          c-1.6-0.7-3.1-1.7-4.6-2.9l-4.3,5.1c2,1.8,4.2,3.1,6.6,4c2.4,0.9,4.9,1.3,7.4,1.3c1.8,0,3.4-0.2,4.9-0.7c1.5-0.5,2.7-1.1,3.8-2
          s1.9-1.9,2.5-3.2C160.2,50.7,160.5,49.3,160.5,47.7L160.5,47.7z M197.5,57.4l-14-18.9l13.4-13.9h-8.7l-13.3,14.4V24.5h-7.2v32.9h7.2
          v-10l3.8-3.9l10.2,14H197.5L197.5,57.4z M249.5,57.4V24.5h-7.8l-8.6,13.9l-8.6-13.9h-7.8v32.9h7.1V36.1l9.2,13.9h0.2l9.2-14v21.4
          H249.5L249.5,57.4z M283.1,57.4v-6.4h-17.8V44h15.5v-6.4h-15.5V31h17.6v-6.4h-24.7v32.9H283.1L283.1,57.4z"/>
        <path fill="#FF6600" d="M122.2,96.5l-10.5-24.8h-5L96.1,96.5h5.5l2.2-5.5h10.4l2.2,5.5H122.2L122.2,96.5z M112.4,86.2h-6.5l3.3-8
          L112.4,86.2L112.4,86.2z M162.2,92.5l-3.4-3.5c-1,0.9-1.9,1.6-2.9,2.1c-1,0.5-2.2,0.8-3.6,0.8c-1.1,0-2-0.2-2.9-0.6
          c-0.9-0.4-1.6-1-2.2-1.7c-0.6-0.7-1.1-1.5-1.5-2.5c-0.4-0.9-0.5-1.9-0.5-3v-0.1c0-1.1,0.2-2,0.5-3c0.4-0.9,0.8-1.7,1.5-2.4
          c0.6-0.7,1.4-1.3,2.3-1.7c0.9-0.4,1.8-0.6,2.9-0.6c1.2,0,2.4,0.2,3.4,0.7c1,0.5,2,1.2,2.9,2l3.4-4c-0.6-0.6-1.2-1.1-1.8-1.5
          c-0.7-0.5-1.4-0.9-2.1-1.2c-0.8-0.3-1.6-0.6-2.6-0.8c-0.9-0.2-2-0.3-3.1-0.3c-1.9,0-3.6,0.3-5.2,1c-1.6,0.7-2.9,1.6-4,2.7
          c-1.1,1.2-2,2.5-2.6,4c-0.6,1.5-0.9,3.2-0.9,4.9v0.1c0,1.8,0.3,3.4,0.9,5c0.6,1.5,1.5,2.9,2.6,4c1.1,1.1,2.5,2,4,2.7
          c1.5,0.7,3.2,1,5,1c1.2,0,2.3-0.1,3.2-0.3c1-0.2,1.8-0.5,2.7-0.9c0.8-0.4,1.6-0.8,2.2-1.4C160.9,93.8,161.5,93.2,162.2,92.5
          L162.2,92.5z M205,96.5l-10.5-24.8h-5l-10.5,24.8h5.5l2.2-5.5h10.4l2.2,5.5H205L205,96.5z M195.1,86.2h-6.5l3.3-8L195.1,86.2
          L195.1,86.2z M246,84.2L246,84.2c0-1.8-0.3-3.4-0.9-4.9c-0.6-1.5-1.5-2.8-2.7-3.9c-1.2-1.1-2.5-2-4.1-2.6c-1.6-0.6-3.4-0.9-5.3-0.9
          h-9.6v24.6h9.6c1.9,0,3.7-0.3,5.3-0.9c1.6-0.6,3-1.5,4.1-2.6c1.2-1.1,2.1-2.4,2.7-3.9C245.7,87.5,246,85.9,246,84.2L246,84.2z
            M240.3,84.2c0,1.1-0.2,2.1-0.5,3c-0.4,0.9-0.9,1.7-1.5,2.3c-0.6,0.7-1.4,1.2-2.3,1.5c-0.9,0.4-1.9,0.5-3,0.5h-4.2V76.7h4.2
          c1.1,0,2.1,0.2,3,0.5c0.9,0.4,1.7,0.9,2.3,1.5c0.6,0.7,1.1,1.4,1.5,2.4C240.1,82.1,240.3,83.1,240.3,84.2L240.3,84.2L240.3,84.2z
            M284,96.5v-4.8h-13.4v-5.2h11.6v-4.8h-11.6v-5h13.2v-4.8h-18.5v24.6H284L284,96.5z M328,96.5V71.9h-5.8l-6.5,10.4l-6.5-10.4h-5.8
          v24.6h5.3v-16l6.9,10.4h0.1l6.9-10.5v16.1H328L328,96.5z M370.2,71.9h-6.2l-6,9.9l-5.9-9.9h-6.3l9.5,14.9v9.7h5.4v-9.8L370.2,71.9
          L370.2,71.9z"/>
      </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
