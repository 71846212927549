import React, {useState} from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, IconPlay, Modal } from '../../components';

import css from './SectionVideo.module.css';

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  // We are just checking the value for now
  console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
};

const SectionVideo = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes}>
      <video src="https://res.cloudinary.com/dtmtvrgdp/video/upload/v1629418284/video_lw6wrp.mp4" autoplay="autoplay" muted loop poster="video.jpg">
        <source src="video.mp4" type="video/mp4" />
      </video>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <span>Tutoring on-the-go for the</span><span className={css.highlight}>modern student.</span> 
        </h1>
        {/* <span className={css.iconPlay}><IconPlay /></span> */}
      </div>

      {/* <Modal
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div style={{ margin: '1rem' }}>
          <h1 className={css.modalTitle}>
            Tutoring on-the-go for the modern student. 
          </h1>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/T4I2CmNpCeg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Modal> */}
    </div>
  );
};

SectionVideo.defaultProps = { rootClassName: null, className: null };

SectionVideo.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionVideo;
